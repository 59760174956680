const i18nDomains = require('./i18nDomains.json');

function getEnvDomains() {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'dev':
      return i18nDomains.dev;
    case 'stage':
      return i18nDomains.stage;
    case 'prod':
      return i18nDomains.prod;
    default:
      throw new Error(`APP_ENV ${process.env.NEXT_PUBLIC_APP_ENV} is not supported`);
  }
}

const nextTranslateConfig = {
  logBuild: false,
  locales: [
    'bg',
    'cs',
    'da',
    'de',
    'el',
    'en',
    'es',
    'et',
    'fi',
    'fr',
    'hr',
    'hu',
    'it',
    'lt',
    'lv',
    'nl',
    'no',
    'pl',
    'pt',
    'ro',
    'ru',
    'sk',
    'sl',
    'sr',
    'sv',
    'tr',
    'uk',
  ],
  defaultLocale: 'pl',
  pages: {
    '*': ['common', 'footer'],
    '/': ['homePage', 'integrationsPage'],
    '/references': ['referencesPage'],
    '/references/sklep-biegacza': ['referencesPage'],
    '/references/r-gol': ['referencesPage'],
    '/references/4f': ['referencesPage'],
    '/references/decathlon': ['referencesPage'],
    '/references/wittchen': ['referencesPage'],
    '/pricing': ['pricingPage'],
    '/partnership': ['partnersPage'],
    '/contact': ['contactPage'],
    '/SA-info': ['contactPage'],
    '/porownanie/trustmate-vs-opineo': ['homePage'],
    '/porownanie/trustmate-vs-trustedshops': ['homePage', 'trustedshopsPage'],
    '/porownanie/trustmate-vs-zaufane': ['homePage', 'zaufanePage'],
    '/surveys': ['surveysPage', 'referencesPage'],
    '/review-translate': ['reviewTranslatePage', 'referencesPage'],
    '/crossborder-sales': ['reviewTranslatePage', 'referencesPage'],
    '/increase-sales': ['reviewsPage', 'referencesPage'],
    '/google': ['googlePage', 'referencesPage'],
    '/collecting-reviews': ['collectingReviewsPage', 'referencesPage'],
    '/client-contact': ['mediationSystemPage', 'referencesPage'],
    '/integrations': ['integrationsPage', 'referencesPage'],
    '/find-company': ['findCompanyPage'],
    '/categories': ['categoryPage'],
    '/categories/services': ['categoryPage'],
    '/categories/shops': ['categoryPage'],
    '/categories/hotels': ['categoryPage'],
    '/categories/restaurants': ['categoryPage'],
    '/reviews/[slug]': ['profilePage'],
    '/reviews/[slug]/[page]': ['profilePage'],
    '/why-us': ['whyUsPage', 'referencesPage'],
    '/hotels': ['hotelsPage'],
    '/badges': ['badgesPage', 'profilePage'],
    '/product/[productId]/[slug]/[uuid]': ['productPage'],
    '/company/[uuid]/product/[productId]': ['productPage', 'profilePage'],
    '/more-sales': ['reviewsPage', 'referencesPage'],
    '/traffic': ['googlePage', 'referencesPage'],
    '/product-polls': ['surveysPage', 'referencesPage'],
    '/review-collection': ['reviewCollectionPage', 'referencesPage'],
    '/our-team': ['ourTeamPage'],
  },
  localeDetection: false,
  domains: getEnvDomains(),
  loadLocaleFrom: async (lang, ns) => {
    const onlyPlSites = ['trustedshopsPage'];
    if (onlyPlSites.includes(ns)) {
      const locales = await import(`./locales/${lang}/${ns}.json`).then((m) => m.default);
      return locales;
    } else {
      const locales = await import(`./locales/${lang}/${ns}.json`).then((m) => m.default);
      const defaultLocales = await import(`./locales/en/${ns}.json`).then((m) => m.default);
      return { ...defaultLocales, ...locales };
    }
  },
};

module.exports = nextTranslateConfig;
